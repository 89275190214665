import React from "react";
import { NotificationSuccess, NotificationError, NotificationWarning } from "Media/Icons/NotificationSymbols";

import {
    CloseNotificationSuccess,
    CloseNotificationError,
    CloseNotificationWarning,
} from "Media/Icons/NotificationCloseIcon";

export const notificationLevel = {
    error: {
        color: "bg-red-100",
        symbol: <NotificationError />,
        textColor: "text-red-200",
        closeNotification: <CloseNotificationError />,
    },
    warning: {
        color: "bg-yellow-100",
        symbol: <NotificationWarning />,
        textColor: "text-yellow-200",
        closeNotification: <CloseNotificationWarning />,
    },
    info: {
        color: "bg-yellow-100",
        symbol: <NotificationWarning />,
        textColor: "text-yellow-200",
        closeNotification: <CloseNotificationWarning />,
    },
    success: {
        color: "bg-green-400",
        symbol: <NotificationSuccess />,
        textColor: "text-green-100",
        closeNotification: <CloseNotificationSuccess />,
    },
    other: {
        color: "bg-yellow-100",
        symbol: <NotificationWarning />,
        textColor: "text-yellow-200",
    },
};
