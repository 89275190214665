import React, { createContext, useState } from "react";

const NotificationsContext = createContext({});

export const NotificationsProvider = ({ children }) => {
    const [showNotification, setShowNotification] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [messageStatus, setMessageStatus] = useState("");

    const setMessage = (message, status) => {
        setErrMsg(message);
        setMessageStatus(status);
        setShowNotification(true);

        setTimeout(() => {
            setShowNotification(false);
        }, 5000);
    };

    return (
        <NotificationsContext.Provider
            value={{ showNotification, errMsg, messageStatus, setMessage, setShowNotification }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsContext;
