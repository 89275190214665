import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import BeatLoader from "react-spinners/BeatLoader";

export const LoaderComponent = ({ showLoader }) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        (promiseInProgress || showLoader) && (
            <div className="loader-component absolute">
                <BeatLoader
                    color="#63A6FD"
                    size={20}
                />
            </div>
        )
    );
};

export default LoaderComponent;
