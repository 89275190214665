/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
import React, { useState, useRef, useEffect } from "react";

import "ol/ol.css";
import "../Style/map.css";
import "../Style/layerMenu.css";

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Map, View } from "ol";
import { fromLonLat } from "ol/proj";
import { boundingExtent } from "ol/extent";
import { transformExtent } from "ol/proj";
import { ZoomToExtent, defaults as defaultControls, ScaleLine } from "ol/control";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { OSM, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import Geolocation from "ol/Geolocation";
import OLCesium from "olcs/OLCesium.js";
import GeoJSON from "ol/format/GeoJSON";

import PositionIcon from "../Media/Icons/PositionIcon";
import HomeIcon from "../Media/Icons/HomeIcon";
import { LayerMenuIcon } from "Media/Icons/LayerMenuIcons";

import rasterLayer from "../Utils/enums";
import HeaderComponent from "Components/HeaderComponent";
import CookieComponent from "Components/Cookie/CookieComponent";
import Notification from "Components/Notification";
import LayerMenu from "Components/LayerMenu";
import { LayerMenuCloseIcon } from "Media/Icons/LayerMenuIcons";
import useNotifications from "Hooks/useNotifications";
import ConfirmModal from "Components/ConfirmModal";

export default function MapPage() {
    let Cesium;

    // eslint-disable-next-line no-unused-vars
    const { setMessage } = useNotifications();

    const [isCookieActive, setIsCookieActive] = useState(true);
    const [positioning, setPositioning] = useState(false);
    const [showModal, setShowModal] = useState(true);

    const doesCookieExist = () => {
        return document.cookie.match(RegExp("(?:^|;\\s*)username=([^;]*)"));
    };

    useState(() => {
        if (doesCookieExist()) {
            setIsCookieActive(false);
        }
        /* setMessage("Successfully uploaded", "success"); // Remove this to remove notification */
    }, []);

    const [map, setMap] = useState();
    const [geolocation, setGeolocation] = useState();
    const [olcs3d, setOlcs3d] = useState();
    const [active3d, setActive3d] = useState(false);

    // Map definitions

    const mapElement = useRef();
    const mapRef = useRef();
    const cesiumMeasure = useRef();
    const [horizontalDistance, setHorizontalDistance] = useState();
    const [trueDistance, setTrueDistance] = useState();
    mapRef.current = map;

    const osmLayer = new OSM();
    const mapViewParams = {
        projection: "EPSG:4326",
        center: [16.708, 43.161],
        zoom: 18,
        maxZoom: 25,
        minZoom: 3,
        extent: transformExtent([16.2, 42.8, 17.0, 43.5], "EPSG:4326", "EPSG:4326"),
    };

    const mapView = new View({
        ...mapViewParams,
        smoothExtentConstraint: true,
    });

    const zoomToExtent = new ZoomToExtent({
        extent: mapViewParams.extent,
        center: mapViewParams.center,
    });

    const scaleLine = new ScaleLine({
        className: "ol-scale-line",
        minWidth: 100,
        maxWidth: 150,
        units: "metric",
    });

    useEffect(() => {
        Cesium = window.Cesium;

        // create map
        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                // OSM
                new TileLayer({
                    source: osmLayer,
                }),
            ],
            view: mapView,
            controls: defaultControls().extend([zoomToExtent, scaleLine]),
        });

        // save map and vector layer references to state
        setMap(initialMap);

        const geolocationInstance = new Geolocation({
            // enableHighAccuracy must be set to true to have the heading value.
            trackingOptions: {
                enableHighAccuracy: true,
            },
            projection: mapView.getProjection(),
        });

        geolocationInstance.on("error", function (error) {
            console.log("Error occured: " + error);
        });

        const accuracyFeature = new Feature();
        geolocationInstance.on("change:accuracyGeometry", function () {
            accuracyFeature.setGeometry(geolocationInstance.getAccuracyGeometry());
        });

        const positionFeature = new Feature();
        positionFeature.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: "#3399CC",
                    }),
                    stroke: new Stroke({
                        color: "#fff",
                        width: 2,
                    }),
                }),
            })
        );

        geolocationInstance.on("change:position", function () {
            const coordinates = geolocationInstance.getPosition();
            positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        });

        setGeolocation(geolocationInstance);

        let geolocationLayer = new VectorLayer({
            map: map,
            source: new VectorSource({
                features: [accuracyFeature, positionFeature],
            }),
            className: "ol-geolocation-layer",
            name: "geolocation-layer",
        });

        initialMap.addLayer(geolocationLayer);

        loadData(initialMap);

        const ol3d = new OLCesium({
            map: initialMap,
            time() {
                return window.Cesium.JulianDate.now();
            },
        });
        //console.log(ol3d);
        setOlcs3d(ol3d);
        const scene = ol3d.getCesiumScene();
        scene.screenSpaceCameraController.enableCollisionDetection = true;

        let terrainProvider = new window.Cesium.CesiumTerrainProvider({
            /* url: window.Cesium.IonResource.fromAssetId(1), */
            url: "https://terrain.listlabs.net/tilesets/terrain/",
        });

        scene.terrainProvider = terrainProvider;

        loadModels(scene);

        addHandler(ol3d);

        return () => {
            try {
                geolocationInstance.off("change:accuracyGeometry", function () {
                    accuracyFeature.setGeometry(geolocationInstance.getAccuracyGeometry());
                });

                geolocationInstance.off("change:position", function () {
                    const coordinates = geolocationInstance.getPosition();
                    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
                });
            } catch (error) {
                console.log(error);
            }
        };
    }, []);

    useEffect(() => {
        if (olcs3d) {
            if (olcs3d.getEnabled()) {
                olcs3d.setEnabled(false);
            } else {
                olcs3d.setEnabled(true);
                let scene = olcs3d.getCesiumScene();
                scene.camera.setView({
                    destination: window.Cesium.Cartesian3.fromDegrees(16.706569646146917, 43.164056627115365, 220),
                    orientation: {
                        heading: window.Cesium.Math.toRadians(180.0),
                        pitch: window.Cesium.Math.toRadians(-40.0),
                        roll: 0.0,
                    },
                });
            }
        }
    }, [active3d]);

    const closeModal = () => {
        setShowModal(false);
    };

    const loadModels = async (scene) => {
        let response = await fetch("https://kampovi-dev.li-st.net/api/trees");

        let data = await response.json();

        let treesMatrix = data.map((feature) => {
            //console.log(feature);
            let matrix = window.Cesium.Transforms.eastNorthUpToFixedFrame(
                window.Cesium.Cartesian3.fromDegrees(
                    feature.geometry.coordinates[0],
                    feature.geometry.coordinates[1],
                    feature.properties.elevation
                )
            );
            //console.log(matrix);
            return matrix;
        });

        /*         let modelMatrix = window.Cesium.Transforms.eastNorthUpToFixedFrame(
            window.Cesium.Cartesian3.fromDegrees(16.707893606700001, 43.161649098899993, 0)
        ); */

        treesMatrix.forEach((matrix) => {
            let model = window.Cesium.Model.fromGltf({
                url: "/tree/model.gltf",
                modelMatrix: matrix,
                scale: 0.3,
            });

            scene.primitives.add(model);
        });

        let responseCaravan = await fetch("https://kampovi-dev.li-st.net/api/caravans");

        let dataCaravan = await responseCaravan.json();

        let caravanMatrix = dataCaravan.map((feature) => {
            let matrix = window.Cesium.Transforms.eastNorthUpToFixedFrame(
                window.Cesium.Cartesian3.fromDegrees(
                    feature.geometry.coordinates[0],
                    feature.geometry.coordinates[1],
                    feature.properties.elevation
                )
            );
            return matrix;
        });

        caravanMatrix.forEach((matrix) => {
            let model = window.Cesium.Model.fromGltf({
                url: `/caravan/model.gltf`,
                modelMatrix: matrix,
                scale: 0.023,
            });

            scene.primitives.add(model);
        });
    };

    const addHandler = (viewer) => {
        let scene = viewer.getCesiumScene();

        let points = scene.primitives.add(new Cesium.PointPrimitiveCollection());
        let point1, point2;
        let point1GeoPosition, point2GeoPosition, point3GeoPosition;
        let polylines = scene.primitives.add(new Cesium.PolylineCollection());
        let polyline1, polyline2, polyline3;
        let distanceLabel, verticalLabel, horizontalLabel;
        let LINEPOINTCOLOR = Cesium.Color.RED;
        var ellipsoid = Cesium.Ellipsoid.WGS84;
        var geodesic = new Cesium.EllipsoidGeodesic();

        /*         var label = {
            font: "14px monospace",
            showBackground: true,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            pixelOffset: new Cesium.Cartesian2(0, 0),
            eyeOffset: new Cesium.Cartesian3(0, 0, -50),
            fillColor: Cesium.Color.WHITE,
        }; */

        function addDistanceLabel(point1, point2, height) {
            point1.cartographic = ellipsoid.cartesianToCartographic(point1.position);
            point2.cartographic = ellipsoid.cartesianToCartographic(point2.position);
            point1.longitude = parseFloat(Cesium.Math.toDegrees(point1.position.x));
            point1.latitude = parseFloat(Cesium.Math.toDegrees(point1.position.y));
            point2.longitude = parseFloat(Cesium.Math.toDegrees(point2.position.x));
            point2.latitude = parseFloat(Cesium.Math.toDegrees(point2.position.y));
            /* label.text = getHorizontalDistanceString(point1, point2);
            horizontalLabel = viewer.entities.add({
                position: getMidpoint(point1, point2, point1GeoPosition.height),
                label: label,
            });
            label.text = getDistanceString(point1, point2);
            distanceLabel = viewer.entities.add({
                position: getMidpoint(point1, point2, height),
                label: label,
            });
            label.text = getVerticalDistanceString();
            verticalLabel = viewer.entities.add({
                position: getMidpoint(point2, point2, height),
                label: label,
            }); */
            const distH = getHorizontalDistanceString(point1, point2);
            const dist = getDistanceString(point1, point2);
            setHorizontalDistance(distH);
            setTrueDistance(dist);
        }

        function getHorizontalDistanceString(point1, point2) {
            geodesic.setEndPoints(point1.cartographic, point2.cartographic);
            var meters = geodesic.surfaceDistance.toFixed(2);
            if (meters >= 1000) {
                return (meters / 1000).toFixed(1) + " км";
            }
            return meters + " м";
        }

        function getVerticalDistanceString() {
            var heights = [point1GeoPosition.height, point2GeoPosition.height];
            var meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
            if (meters >= 1000) {
                return (meters / 1000).toFixed(1) + " км";
            }
            return meters.toFixed(2) + " м";
        }

        function getDistanceString(point1, point2) {
            geodesic.setEndPoints(point1.cartographic, point2.cartographic);
            var horizontalMeters = geodesic.surfaceDistance.toFixed(2);
            var heights = [point1GeoPosition.height, point2GeoPosition.height];
            var verticalMeters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
            var meters = Math.pow(Math.pow(horizontalMeters, 2) + Math.pow(verticalMeters, 2), 0.5);

            if (meters >= 1000) {
                return (meters / 1000).toFixed(1) + " км";
            }
            return meters.toFixed(2) + " м";
        }

        function getMidpoint(point1, point2, height) {
            var scratch = new Cesium.Cartographic();
            geodesic.setEndPoints(point1.cartographic, point2.cartographic);
            var midpointCartographic = geodesic.interpolateUsingFraction(0.5, scratch);
            return Cesium.Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, height);
        }

        let handler = new Cesium.ScreenSpaceEventHandler(scene.canvas);

        const inputAction = (click) => {
            if (scene.mode !== Cesium.SceneMode.MORPHING) {
                var pickedObject = scene.pick(click.position);
                if (scene.pickPositionSupported && Cesium.defined(pickedObject)) {
                    console.log(scene.pickPositionSupported);
                    var cartesian = scene.pickPosition(click.position);
                    // console.log(cartesian);
                    if (Cesium.defined(cartesian)) {
                        if (points.length === 2) {
                            points.removeAll();
                            polylines.removeAll();
                            /* viewer.entities.remove(distanceLabel);
                            viewer.entities.remove(horizontalLabel);
                            viewer.entities.remove(verticalLabel); */
                            setHorizontalDistance();
                            setTrueDistance();
                        }
                        //add first point
                        if (points.length === 0) {
                            point1 = points.add({
                                position: new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z),
                                color: LINEPOINTCOLOR,
                            });
                        } //add second point and lines
                        else if (points.length === 1) {
                            point2 = points.add({
                                position: new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z),
                                color: LINEPOINTCOLOR,
                            });
                            point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position);
                            point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position);
                            // eslint-disable-next-line no-undef
                            point3GeoPosition = Cesium.Cartographic.fromCartesian(
                                new Cesium.Cartesian3(point2.position.x, point2.position.y, point1.position.z)
                            );

                            var pl1Positions = [
                                new Cesium.Cartesian3.fromRadians(
                                    point1GeoPosition.longitude,
                                    point1GeoPosition.latitude,
                                    point1GeoPosition.height
                                ),
                                new Cesium.Cartesian3.fromRadians(
                                    point2GeoPosition.longitude,
                                    point2GeoPosition.latitude,
                                    point2GeoPosition.height
                                ),
                            ];
                            var pl2Positions = [
                                new Cesium.Cartesian3.fromRadians(
                                    point2GeoPosition.longitude,
                                    point2GeoPosition.latitude,
                                    point2GeoPosition.height
                                ),
                                new Cesium.Cartesian3.fromRadians(
                                    point2GeoPosition.longitude,
                                    point2GeoPosition.latitude,
                                    point1GeoPosition.height
                                ),
                            ];
                            var pl3Positions = [
                                new Cesium.Cartesian3.fromRadians(
                                    point1GeoPosition.longitude,
                                    point1GeoPosition.latitude,
                                    point1GeoPosition.height
                                ),
                                new Cesium.Cartesian3.fromRadians(
                                    point2GeoPosition.longitude,
                                    point2GeoPosition.latitude,
                                    point1GeoPosition.height
                                ),
                            ];

                            polyline1 = polylines.add({
                                show: true,
                                positions: pl1Positions,
                                width: 1,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: "Color",
                                        uniforms: {
                                            color: LINEPOINTCOLOR,
                                        },
                                    },
                                }),
                            });
                            polyline2 = polylines.add({
                                show: true,
                                positions: pl2Positions,
                                width: 1,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: "PolylineDash",
                                        uniforms: {
                                            color: LINEPOINTCOLOR,
                                        },
                                    },
                                }),
                            });
                            polyline3 = polylines.add({
                                show: true,
                                positions: pl3Positions,
                                width: 1,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: "PolylineDash",
                                        uniforms: {
                                            color: LINEPOINTCOLOR,
                                        },
                                    },
                                }),
                            });
                            var labelZ;
                            if (point2GeoPosition.height >= point1GeoPosition.height) {
                                labelZ =
                                    point1GeoPosition.height +
                                    (point2GeoPosition.height - point1GeoPosition.height) / 2.0;
                            } else {
                                labelZ =
                                    point2GeoPosition.height +
                                    (point1GeoPosition.height - point2GeoPosition.height) / 2.0;
                            }

                            addDistanceLabel(point1, point2, labelZ);
                        }
                    }
                }
            }
        };

        handler.setInputAction(inputAction, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    };

    const toggleTracking = () => {
        const trackingStatus = geolocation.getTracking();

        if (trackingStatus === false) {
            geolocation.setTracking(true);
            map.getLayers().forEach((el) => {
                if (el.get("name") === "geolocation-layer") {
                    el.setVisible(true);
                    //console.log(el.getFeatures());
                }
            });
        } else if (trackingStatus === true) {
            geolocation.setTracking(false);
            map.getLayers().forEach((el) => {
                if (el.get("name") === "geolocation-layer") {
                    el.setVisible(false);
                    //console.log(el.getFeatures());
                }
            });
        }
    };

    const loadData = async (initialMap) => {
        let response = await fetch("https://kampovi-dev.li-st.net/api/parcels");

        let data = await response.json();

        const geojsonObject = {
            type: "FeatureCollection",
            crs: {
                type: "name",
                properties: {
                    name: "EPSG:4326",
                },
            },
            features: data,
        };

        const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonObject),
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
                stroke: new Stroke({
                    color: "blue",
                    lineDash: [4],
                    width: 3,
                }),
                fill: new Fill({
                    color: "rgba(255, 255, 0, 0.4)",
                    opacity: 0.5,
                }),
            }),
        });

        vectorLayer.set("altitudeMode", "clampToGround");

        initialMap.addLayer(vectorLayer);
    };

    const [menuOpen, setMenuOpen] = useState(false);
    function setLayerMenu() {
        setMenuOpen(!menuOpen);
    }

    const handle3dClick = () => {
        setActive3d((prev) => !prev);
    };

    return (
        <div className="z-0 h-full overflow-hidden wrapper">
            <div className="relative bottom-0 top-20 md:top-14">
                <div
                    ref={mapElement}
                    className="map-container"
                >
                    {/*DESKTOP LAYER MENU*/}
                    {/* <div
                        className="absolute z-[6000]"
                        onClick={setLayerMenu}
                    >
                        {menuOpen ? (
                            <button className="md:w-[55px] md:h-[55px] md:bg-orange-100 absolute md:top-0 md:left-auto left-[334px] top-10">
                                <LayerMenuCloseIcon />
                            </button>
                        ) : (
                            <button
                                className="bg-white w-[60px] h-[60px] absolute top-6 left-6 rounded-[10px] flex justify-center items-center hover:bg-green-100 hover-state 
                            md:bg-orange-100 md:w-[55px] md:h-[55px] md:left-0 mobile-layer-icon-closed md:top-0 md:mt-[-56px] md:rounded-none"
                            >
                                <LayerMenuIcon />
                            </button>
                        )}
                    </div> */}
                    <div className="z-[4000]">{menuOpen && <LayerMenu />}</div>
                    <button
                        onClick={toggleTracking}
                        className="ol-locate-button"
                    >
                        <PositionIcon />
                    </button>
                    <div
                        id="measureContainer"
                        ref={cesiumMeasure}
                    ></div>
                    <div
                        className="bg-white absolute top-32 left-6 z-[5000] p-4"
                        hidden={!(horizontalDistance && trueDistance)}
                    >
                        <p>Horizontal distance: {horizontalDistance}</p>
                        <p>True distance: {trueDistance}</p>
                    </div>
                </div>
            </div>
            <HeaderComponent
                mapRef={map}
                handle3dClick={handle3dClick}
            ></HeaderComponent>
            <Notification />
            {/* <ConfirmModal
                show={showModal}
                title="Brisanje podataka"
                handleCancel={closeModal}
                handleConfirm={closeModal}
                bodyText="Jeste li sigurni da želite obrisati odabrane podatke? Svi Vaši podaci bit će trajno uklonjeni. Ova akcija se ne može poništiti."
            /> */}
            {/* {isCookieActive && <CookieComponent />} */}
        </div>
    );
}
