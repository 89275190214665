import React from "react";
import {
    CompareIcon,
    InfoIcon,
    MobileVisual,
    MeasureIcon,
} from "Media/Icons/HeaderComponentIcons";


const SettingsMenu=() =>{
    return(
        <>
            <div className="pb-3">
                <MobileVisual/>
            </div>
            <button
                title="Usporedi"
                className="w-[44px] h-[44px] rounded-lg flex justify-center items-center btns-color hover:bg-green-500"
            >
                <CompareIcon />
            </button>
            <button
                title="3D"
                className="w-[44px] h-[44px] rounded-lg flex justify-center items-center btns-color hover:bg-green-500"
            >
                <MeasureIcon />
            </button>
            <button
                title="Info"
                className="w-[44px] h-[44px] rounded-lg flex justify-center items-center btns-color hover:bg-green-500 mb-5.5"
            >
                <InfoIcon />
            </button>
            
        </>
    );
};

export default SettingsMenu;

