import { LayerMenuIcon } from "Media/Icons/LayerMenuIcons";
import React from "react";

import "../Style/layerMenu.css";

const LayerMenu = () => {
    return (
        <div className="md:mt-[-56px]">
            <div className="w-[60px] h-[60px] bg-green-100 z-[3005] opened-state rounded-tl-[10px] absolute top-6 left-6 flex items-center justify-center md:top-0 md:left-0 md:w-[55px] md:h-[55px] md:rounded-none">
                <LayerMenuIcon />
            </div>
            <div className="bg-green-500 custom-width w-[300px] z-[3005] md:h-[55px] h-[60px] text-white font-medium absolute left-[84px] md:left-[55px] md:top-0 top-6 rounded-tr-[10px] flex justify-between items-center pl-7 pr-4 md:pr-0">
                STANIŠTA
            </div>
            <div className="font-inter bg-white absolute top-[84px] left-6 md:w-full w-[360px] h-[549px] rounded-b-[10px] md:top-[55px] md:left-0 z-[3005]">
                <div className="fixed ml-[30px] mt-[22px] w-[2px] h-[298px] bg-green-100 opacity-30"></div>
                <div className="flex flex-col justify-around align-center gap-[11px] text-gray-400 ml-[30px] mt-[62px]">
                    <div className="flex items-center">
                        <div className="w-[13px] h-[2px] bg-green-100 opacity-30"></div>
                        <div className="w-[6px] h-[6px] rounded-[50%] bg-green-100 mr-4"></div>
                        <input
                            className="border-orange-400 border-2 mr-[10px] hover:bg-orange-400 rounded-sm"
                            type="checkbox"
                        ></input>
                        Kopnena staništa
                    </div>
                    <div className="flex items-center">
                        <div className="w-[13px] h-[2px] bg-green-100 opacity-30"></div>
                        <div className="w-[6px] h-[6px] rounded-[50%] bg-green-100 mr-4"></div>
                        <input
                            className="border-red-600 border-2 mr-[10px] hover:bg-red-600 rounded-sm"
                            type="checkbox"
                        ></input>
                        Niska vegetacija
                    </div>
                    <div className="flex items-center">
                        <div className="w-[13px] h-[2px] bg-green-100 opacity-30"></div>
                        <div className="w-[6px] h-[6px] rounded-[50%] bg-green-100 mr-4"></div>
                        <input
                            className="border-green-300 border-2 mr-[10px] hover:bg-green-300 rounded-sm"
                            type="checkbox"
                        ></input>
                        Travnjaci, mahovine, lišajevi
                    </div>
                    <div className="flex items-center">
                        <div className="w-[13px] h-[2px] bg-green-100 opacity-30"></div>
                        <div className="w-[6px] h-[6px] rounded-[50%] bg-green-100 mr-4"></div>
                        <input
                            className="border-yellow-300 border-2 mr-[10px] hover:bg-yellow-300 rounded-sm"
                            type="checkbox"
                        ></input>
                        Vegetirani tipovi staništa
                    </div>
                    <div className="flex items-center">
                        <div className="w-[13px] h-[2px] bg-green-100 opacity-30"></div>
                        <div className="w-[6px] h-[6px] rounded-[50%] bg-green-100 mr-4"></div>
                        <input
                            className="border-rose-400 border-2 mr-[10px] hover:bg-rose-400 rounded-sm"
                            type="checkbox"
                        ></input>
                        Priobalna staništa
                    </div>
                    <div className="flex items-center">
                        <div className="w-[13px] h-[2px] bg-green-100 opacity-30"></div>
                        <div className="w-[6px] h-[6px] rounded-[50%] bg-green-100 mr-4"></div>
                        <input
                            className="border-purple-400 border-2 mr-[10px] hover:bg-purple-400 rounded-sm"
                            type="checkbox"
                        ></input>
                        Šume i šumska područja
                    </div>
                    <div className="flex items-center">
                        <div className="w-[13px] h-[2px] bg-green-100 opacity-30"></div>
                        <div className="w-[6px] h-[6px] rounded-[50%] bg-green-100 mr-4"></div>
                        <input
                            className="border-green-700 border-2 mr-[10px] hover:bg-green-700 rounded-sm"
                            type="checkbox"
                        ></input>
                        Vriština, šikara i tundra
                    </div>
                    <div className="flex items-center">
                        <div className="w-[13px] h-[2px] bg-green-100 opacity-30"></div>
                        <div className="w-[6px] h-[6px] rounded-[50%] bg-green-100 mr-4"></div>
                        <input
                            className="border-cyan-300 border-2 mr-[10px] hover:bg-cyan-300 rounded-sm"
                            type="checkbox"
                        ></input>
                        Obalni tipovi staništa
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayerMenu;
