import { Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useRefreshToken from "Hooks/useRefreshToken";
import useAuth from "Hooks/useAuth";
import LoaderComponent from "Components/LoaderComponent";
const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }

            return () => (isMounted = false);
        };

        !auth.access ? verifyRefreshToken() : setIsLoading(false);
    }, []);

    useEffect(() => {
        //console.log(`isLoading: ${isLoading}`);
        //console.log(`aT: ${JSON.stringify(auth?.access)}`);
    }, [isLoading]);

    return <>{!persist ? <Outlet /> : isLoading ? <LoaderComponent showLoader={true} /> : <Outlet />}</>;
};

export default PersistLogin;
