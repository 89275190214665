import React, { useState } from "react";
import {
    CompareIcon,
    MeasureIcon,
    ThreeDIcon,
    InfoIcon,
    AddPolygonIcon,
    FingerprintIcon,
    SettingsIcon,
    MobileLoginIcon,
    SettingsIconOpened,
} from "Media/Icons/HeaderComponentIcons";

import SettingsMenu from "./SettingsMenu";
import "../Style/header.css";

const HeaderComponent = ({ handle3dClick }) => {
    // Handler for header menu opening
    const [settingsOpen, setSettingsOpen] = useState(false);
    function setSettingsMenu() {
        setSettingsOpen(!settingsOpen);
    }

    return (
        <>
            <div className="absolute top-0 flex justify-between bg-green-100 h-20 md:h-[55px] z-[3000] w-screen font-inter flex-row items-center text-white">
                {/*DESKTOP HEADER*/}
                <div className="text-white w-fit md:hidden pl-4">Dio sa pretragom</div>
                <div className="flex justify-around gap-x-[5px] md:hidden">
                    <button
                        title="Usporedi"
                        className="w-[44px] h-[44px] flex justify-center items-center bg-gray-500 rounded-[50%]"
                        
                    >
                        <CompareIcon />
                    </button>
                    <buttton
                        title="Dodaj poligon"
                        className="w-[44px] h-[44px] flex justify-center items-center bg-gray-500 rounded-[50%]"
                        
                    >
                        <AddPolygonIcon />
                    </buttton>
                    <button
                        title="Mjerenje"
                        className="w-[44px] h-[44px] flex justify-center items-center bg-gray-500 rounded-[50%]"
                        
                    >
                        <MeasureIcon />
                    </button>

                    <button
                        title="3D"
                        className="w-[44px] h-[44px] flex justify-center items-center bg-green-500 rounded-[50%] hover:bg-white state-hover-color"
                        onClick={handle3dClick}
                    >
                        <ThreeDIcon />
                    </button>

                    <button
                        title="Info"
                        className="w-[44px] h-[44px] flex justify-center items-center bg-gray-500 rounded-[50%]"
                        
                    >
                        <InfoIcon />
                    </button>
                </div>
                <a
                    title="Login"
                    href=""
                    className="text-white pr-4 md:hidden"
                >
                    Login <FingerprintIcon />
                </a>
                {/*MOBILE HEADER*/}
                <div className="md:left-0 md:m-auto md:w-full md:flex md:items-center md:justify-end pl-0 pr-[11px] hidden">
                    <a href="">
                        <MobileLoginIcon />
                    </a>
                </div>
                <div
                    className={`${
                        settingsOpen ? "rounded-b-none rounded-t-lg" : "md:rounded-lg"
                    } md:absolute md:left-[3px] origin-center md:top-16 md:w-[50px] md:bg-white md:bg-opacity-90 md:flex items-center flex-col hidden py-3.5`}
                >
                    <button
                        className="bg-white md:rounded-lg md:flex items-center justify-center"
                        onClick={setSettingsMenu}
                    >
                        {settingsOpen ? <SettingsIconOpened /> : <SettingsIcon />}
                    </button>
                </div>
                <div
                    className={`header-settings ${
                        settingsOpen ? "opened rounded-b-lg" : ""
                    } md:absolute md:left-[3px] origin-center md:top-[120px] md:w-[50px] md:bg-white md:bg-opacity-90 md:flex items-center flex-col hidden rounded-b-lg pt-1 pb-3.5`}
                >
                    {settingsOpen && <SettingsMenu />}
                </div>
            </div>
        </>
    );
};
export default HeaderComponent;
