import React from "react";

export const NotificationSuccess = () => {
    return (
        <svg
            width="20"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 19.2A9.6 9.6 0 1 0 10 0a9.6 9.6 0 0 0 0 19.2ZM14.45 8.048a1.2 1.2 0 0 0-1.697-1.696L8.8 10.303 7.25 8.752a1.2 1.2 0 0 0-1.697 1.696l2.4 2.4a1.2 1.2 0 0 0 1.697 0l4.8-4.8Z"
                fill="#34D399"
            />
        </svg>
    );
};

export const NotificationWarning = () => {
    return (
        <svg
            width="20"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.908 1.719c.918-1.632 3.267-1.632 4.183 0l6.697 11.904c.9 1.6-.256 3.576-2.09 3.576H3.303c-1.836 0-2.992-1.975-2.092-3.576L7.908 1.719ZM11.2 13.6a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0ZM10 4a1.2 1.2 0 0 0-1.2 1.2v3.6a1.2 1.2 0 0 0 2.4 0V5.2A1.2 1.2 0 0 0 10 4Z"
                fill="#FBBF24"
            />
        </svg>
    );
};

export const NotificationError = () => {
    return (
        <svg
            width="20"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 19.6A9.6 9.6 0 1 0 10 .4a9.6 9.6 0 0 0 0 19.2ZM8.448 6.75a1.2 1.2 0 0 0-1.696 1.697L8.303 10l-1.551 1.552a1.2 1.2 0 1 0 1.696 1.696L10 11.697l1.552 1.551a1.2 1.2 0 0 0 1.696-1.696L11.697 10l1.551-1.552a1.2 1.2 0 0 0-1.696-1.697L10 8.303 8.448 6.751Z"
                fill="#DE6E4B"
            />
        </svg>
    );
};
