import React from "react";
import useNotifications from "../Hooks/useNotifications";
import { notificationLevel } from "Utils/variables";

const Notification = () => {
    const { showNotification, setShowNotification, errMsg, messageStatus } = useNotifications();

    return (
        <>
            {showNotification && errMsg && (
                <div
                    className={`notification severity-${messageStatus} absolute top-[90px] sm:top-[65px] w-auto h-[50px] z-[6000] px-[15px] rounded-[5px] inset-[18px] flex flex-row justify-between items-center ${
                        notificationLevel[messageStatus].color || notificationLevel["other"].color
                    }`}
                >
                    <div className="flex justify-start">
                        <div className="pr-5">
                            {notificationLevel[messageStatus].symbol || notificationLevel.other.symbol}
                        </div>
                        <h4
                            className={` ${
                                notificationLevel[messageStatus].textColor || notificationLevel.other.textColor
                            }`}
                        >
                            {errMsg}
                        </h4>
                    </div>
                    <button
                        className={`flex justify-end ${
                            notificationLevel[messageStatus].textColor || notificationLevel.other.textColor
                        }`}
                        onClick={() => setShowNotification(false)}
                    >
                        {notificationLevel[messageStatus].closeNotification ||
                            notificationLevel.other.closeNotification}
                    </button>
                </div>
            )}
        </>
    );
};

export default Notification;

/* 
"error"
"warning"
"info"
"success" 
*/
