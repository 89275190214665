import React, { useEffect, useRef, useState } from "react";
import axios from "Services/axios";
import useAuth from "Hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import useNotifications from "Hooks/useNotifications";
import "../Style/login.css";
import LogoIcon from "Media/Icons/Logo";


const LOGIN_URL = "user/token/";

const LoginPage = () => {
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const usernameRef = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { setMessage } = useNotifications();

    useEffect(() => {
        usernameRef.current.focus();
    }, []);

    useEffect(() => {
        setMessage("");
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const bodyFormData = new FormData();
        bodyFormData.append("username", username);
        bodyFormData.append("password", password);
        try {
            const response = await axios.post(LOGIN_URL, bodyFormData, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            //console.log(JSON.stringify(response.data));

            const access = response?.data?.access;
            setAuth({ access });
            setPassword("");
            setUsername("");

            if (!persist) sessionStorage.setItem("accessToken", JSON.stringify(access));

            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setMessage("No server response!", "error");
            } else if (err.response?.status === 400) {
                setMessage("Missing username or password", "error");
            } else if (err.response?.status === 401) {
                setMessage("Unauthorized", "error");
            } else {
                setMessage("Something went wrong!", "error");
            }
        }
    };

    const togglePersist = () => {
        setPersist((prev) => !prev);
    };

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist]);

    return (
        <div className="font-inter h-screen flex justify-center items-center leading-tight bg-login-bg bg-no-repeat bg-cover">
            <div className="bg-white opacity-[.95] sm:h-full sm:w-full h-[600px] w-[468px] flex items-center justify-center px-[55px] rounded-[20px] sm:rounded-none custom-border-input">
                <form
                    onSubmit={handleSubmit}
                    className="text-gray-400 flex flex-col max-w-[268px]"
                >
                    <h1 className="pb-4 pt-11 ml-[10%] custom-logo"><LogoIcon/></h1>
                    <h2 className="pb-7 text-[12px] px-6 text-center">
                        Lorem ipsum lorem ipsum dolore sns et mi doroloe micum pale ale
                    </h2>
                    <label className="text-left test pb-0.5 text-m text-gray-400 pl-[10px]">Username</label>
                    <input
                        className="h-[40px] rounded-[20px] border-blue-300 mb-4 text-m text-gray-500 pl-[18px]"
                        placeholder="Username"
                        type="text"
                        name="username"
                        autoFocus
                        autoCapitalize="none"
                        maxLength="150"
                        ref={usernameRef}
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    ></input>
                    <label className="text-left pb-0.5 text-m text-gray-400 pl-[10px]">Password</label>
                    <input
                        className="h-[40px] rounded-[20px] text-gray-500 text-m mb-3.5 pl-[18px]"
                        placeholder="Password"
                        type="password"
                        name="password"
                        autoFocus
                        autoCapitalize="none"
                        maxLength="150"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    ></input>
                    <div className="mb-[62px] flex justify-start text-sm pl-[10px]">
                        <input
                            type="checkbox"
                            onChange={togglePersist}
                            checked={persist}
                        ></input>
                        <p className="pl-[5px] text-sm">Remember me</p>
                    </div>
                    <button className="bg-green-100 text-white text-base hover:bg-green-300 h-[44px] rounded-[20px] mb-[85px]">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
