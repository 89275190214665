/* eslint-disable react/display-name */
import React from "react";

export const combineContexts = (...components) => {
    return components.reduce(
        (AccumulatedContexts, CurrentContext) => {
            return ({ children }) => {
                return (
                    <AccumulatedContexts>
                        <CurrentContext>{children}</CurrentContext>
                    </AccumulatedContexts>
                );
            };
        },
        ({ children }) => <>{children}</>
    );
};
