import React, { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "Hooks/useAuth";
import LoaderComponent from "Components/LoaderComponent";

const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const sessionToken = JSON.parse(sessionStorage.getItem("accessToken"));

        if (auth?.access) {
            setLoading(false);
        } else if (!auth.access && sessionToken) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } else {
            setLoading(false);
        }
    });

    return isLoading ? (
        <LoaderComponent showLoader={true} />
    ) : auth?.access ? (
        <Outlet />
    ) : (
        <Navigate
            to="/login"
            state={{ from: location }}
            replace
        />
    );
};

export default RequireAuth;
