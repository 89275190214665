import "./App.css";

// Hooks, services
import React from "react";
import { AppContextProvider } from "./Services/AppContext";
import { Routes, Route } from "react-router-dom";

// Components
import LoginPage from "Pages/LoginPage";
import PersistLogin from "./Components/Auth/PersistLogin";
import RequireAuth from "./Components/Auth/RequireAuth";
/* import Notification from "Components/Notification"; */
import MapPage from "Pages/MapPage";

function App() {
    return (
        <AppContextProvider>
            {/* Add new high level context to AppContextProvider, not as children! */}
            {/* <Notification /> */}
            <script></script>
            <Routes>
                <Route
                    path="login"
                    element={<LoginPage />}
                />
                <Route
                    path="logout"
                    element={<LoginPage />}
                />
                {/* Root goes to protected route when API established! TODO */}
                <Route
                    path="/"
                    element={<MapPage />}
                />
                {/* Protected Routes */}
                {/* TBD on protected routes */}
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth />}></Route>
                </Route>
            </Routes>
        </AppContextProvider>
    );
}

export default App;
