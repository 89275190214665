import React from "react";

const PositionIcon = () => {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8 1.2C10.8 0.537258 11.3373 0 12 0C12.6627 0 13.2 0.537259 13.2 1.2V3.68504C16.879 4.2111 19.7891 7.12105 20.3153 10.8H22.8C23.4627 10.8 24 11.3373 24 12C24 12.6627 23.4627 13.2 22.8 13.2H20.3153C19.7891 16.879 16.879 19.7889 13.2 20.315V22.8C13.2 23.4627 12.6627 24 12 24C11.3373 24 10.8 23.4627 10.8 22.8V20.3149C7.12135 19.7885 4.21164 16.8787 3.68545 13.2H1.2C0.537258 13.2 0 12.6627 0 12C0 11.3373 0.537259 10.8 1.2 10.8H3.68546C4.21167 7.12131 7.12136 4.21151 10.8 3.68515V1.2ZM12.0004 18C15.3141 18 18.0004 15.3137 18.0004 12C18.0004 8.68632 15.3141 6.00002 12.0004 6.00002C8.68668 6.00002 6.00039 8.68632 6.00039 12C6.00039 15.3137 8.68668 18 12.0004 18Z"
                fill="#333333"
            />
        </svg>
    );
};

export default PositionIcon;
